import { decorateWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/wish-decorator";
import * as Price from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/price";
import {
  canUnpurchase,
  getLatestPurchaseByUser,
  getLatestRevertablePurchase,
} from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/purchase";
import {
  purchaseWish,
  unpurchaseWish,
} from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/wish";
import {
  faExternalLinkAlt,
  faHashtag,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PubSub from "pubsub-js";
import Swal from "sweetalert2";
import { formatDate } from "../../../../helpers/date";
import { withI18n } from "../../../../hooks/use-i18n";
import GiftFinderSaveButton from "../GiftFinderSaveButton";
import WishActions from "../WishActions";
import { ProcessingStatus } from "./ProcessingStatus";
import { PromptLoginModal } from "./PromptLoginModal";
import { PurchaseHistory } from "./PurchaseHistory";
import { getWishDescription } from "./helper";

import './index.less';

class WishRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedDescription: false,
      requestedUserName: false,
      user: props.user,
    };

    PubSub.subscribe("page.refresh", (_msg, { user }) => {
      this.setState({ user });
    });
  }

  async purchaseWish() {
    const { wishlist, uuid, purchases } = this.props;
    const { user } = this.state;

    if (!user && !this.state.requestedUserName) {
      return this.setState({ requestedUserName: true });
    }

    const purchase = await purchaseWish(null, wishlist.uuid, uuid);
    this.props.onChange({ purchases: [...purchases, purchase] });
  }

  async unpurchaseWish() {
    const { i18n, wishlist, user } = this.props;
    const purchase = getLatestRevertablePurchase(this.props, wishlist, user);

    Swal.fire({
      title: i18n.t("wishlist.unreserve.confirmTitle"),
      text: i18n.t("wishlist.unreserve.confirmBody"),
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: i18n.t("wishlist.unreserve.confirmButton"),
      denyButtonText: i18n.t("common.cancel"),
      customClass: {
        htmlContainer: "swal-small-text",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { ok } = await unpurchaseWish(
          null,
          this.props.wishlist.uuid,
          this.props.uuid,
          purchase.id
        );

        if (ok) {
          this.props.onChange({
            purchases: this.props.purchases.filter((p) => p.id !== purchase.id),
          });

          Swal.fire({
            title: i18n.t("wishlist.unreserve.successfullyUnreserved"),
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: i18n.t("wishlist.unreserve.failedUnreserved"),
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    });
  }

  toggleDescription(e) {
    e.preventDefault();

    this.setState({ expandedDescription: !this.state.expandedDescription });
  }

  render() {
    const { i18n, forOwner, purchases, wishlist, user } = this.props;
    const decoratedWish = decorateWish(this.props);
    const showPurchaseButton = !decoratedWish.completed;
    const showReturnButton = canUnpurchase(this.props, wishlist, user);
    const wasPurchased = !!getLatestPurchaseByUser(purchases, user);
    const labelKey = wasPurchased
      ? "wishlist.willBuyAgain"
      : "wishlist.willBuy";
    const price =
      this.props.cents &&
      Price.format(this.props.cents, this.props.currency, i18n.currentLocale());

    return (
      <>
        {this.state.requestedUserName && (
          <PromptLoginModal
            wishlist={this.props.wishlist}
            owner={this.props.wishlist.owner}
            onDismiss={() => this.setState({ requestedUserName: false })}
            onComplete={({ user }) => {
              this.setState({ user });
              this.purchaseWish();
            }}
          />
        )}
        <div className="post-img">
          <a
            href={this.props.affiliateUrl || this.props.url}
            target="_blank"
            rel="nofollow sponsored"
            className="wish-image-link"
          >
            <div
              className="image-wrapper"
              style={{ backgroundImage: `url(${this.props.image})` }}
            />
          </a>
          <ProcessingStatus wish={decoratedWish} />
        </div>

        <div className="list-content">
          <div className="post-header ">
            <h2>
              <a
                href={this.props.affiliateUrl || this.props.url}
                target="_blank"
                rel="nofollow sponsored"
              >
                {this.props.title}
              </a>
            </h2>
          </div>

          <div
            className={classNames(
              "post-entry",
              "is-excerpt",
              "item-description"
            )}
          >
            {getWishDescription(this.props) && (
              <p
                onClick={(e) => this.toggleDescription(e)}
                className={classNames({
                  expanded: this.state.expandedDescription,
                })}
              >
                {getWishDescription(this.props)}
              </p>
            )}

            {this.props.giftfinder || (
              <div className="wish-actions">
                {showPurchaseButton && (
                  <a
                    className="read-more action buy-now"
                    onClick={() => this.purchaseWish()}
                  >
                    {i18n.t(labelKey)}
                  </a>
                )}
                {showReturnButton && (
                  <a
                    className="read-more action cancel-buy"
                    onClick={() => this.unpurchaseWish()}
                  >
                    {i18n.t("wishlist.nevermind")}
                  </a>
                )}
              </div>
            )}

            {this.props.giftfinder && (
              <GiftFinderSaveButton wish={this.props} />
            )}
          </div>

          <div className="post-meta-info">
            <div className="meta-left">
              <span className="date">
                {this.props.affiliateUrl && (
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    className="icon"
                    style={{ marginRight: "4px" }}
                  />
                )}
                <span className="by">
                  <FontAwesomeIcon icon={faPlusSquare} className="icon" />
                </span>
                {formatDate(this.props.createdAt)}
              </span>
            </div>
            {decoratedWish.remainingQuantity > 1 && (
              <div className="meta-center">
                <FontAwesomeIcon
                  icon={faHashtag}
                  className="icon"
                  style={{ marginRight: "4px" }}
                />
                <span>{decoratedWish.remainingQuantity} x</span>
              </div>
            )}
            <div className="meta-right">
              {!!price && (
                <span className="price">
                  <a
                    href={this.props.affiliateUrl || this.props.url}
                    target="_blank"
                    rel="nofollow sponsored"
                  >
                    {price.money}
                  </a>
                </span>
              )}
            </div>
          </div>

          <PurchaseHistory wish={this.props} wishlist={wishlist} user={user} />

          {this.props.forOwner && (
            <div className="post-meta">
              <WishActions {...this.props} />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withI18n(WishRenderer);
