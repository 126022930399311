import { decorateWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/decorators/wish-decorator";
import i18n from "../../../../models/i18n";

export function getWishDescription(wish) {
  const { processing } = decorateWish(wish);

  if (processing.isPending || processing.hasFailed) {
    return i18n.t(`wish.processing.${wish.processingStatus}`);
  }

  return wish.description;
}
